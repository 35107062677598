// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import '@angular/material/theming';

// Plus imports for other components in your app.

@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/symbols.woff2') format('woff2');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  src: url('./assets/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  src: url('./assets/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  src: url('./assets/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  src: url('./assets/Montserrat-Bold.ttf') format('truetype');
}

$md-primary: (
        50: #e7f2f2,
        100: #c4dfdf,
        200: #9acdcc,
        300: #6ebab9,
        400: #51aca9,
        500: #409d99,
        600: #3c908b,
        700: #36807a,
        800: #326f6a,
        900: #27534d,
        a100 : #fff,
        a200 : #fff,
        a400 : #fff,
        a700 : #fff,
        contrast: (
                50 : #000,
                100 : #000,
                200 : #000,
                300 : #000,
                400 : #000,
                500 : #000,
                600 : #000,
                700 : #000,
                800 : #000,
                900 : #000,
                a100 : #000,
                a200 : #000,
                a400 : #000,
                a700 : #000,
        )
);
$md-accent: (
        50 : #ebf3ed,
        100 : #cee2d1,
        200 : #aeceb3,
        300 : #8dba95,
        400 : #74ac7e,
        500 : #5c9d67,
        600 : #54955f,
        700 : #4a8b54,
        800 : #41814a,
        900 : #306f39,
        a100 : #baffc3,
        a200 : #87ff96,
        a400 : #54ff6a,
        a700 : #3bff54,
        contrast: (
                50 : #000,
                100 : #000,
                200 : #000,
                300 : #000,
                400 : #000,
                500 : #000,
                600 : #fff,
                700 : #fff,
                800 : #fff,
                900 : #fff,
                a100 : #000,
                a200 : #000,
                a400 : #000,
                a700 : #000,
        )
);
$md-secondary: (
        50 : #e0e0e0,
        100 : #b3b3b3,
        200 : #808080,
        300 : #4d4d4d,
        400 : #262626,
        500 : #000,
        600 : #000,
        700 : #000,
        800 : #000,
        900 : #000,
        a100 : #a6a6a6,
        a200 : #8c8c8c,
        a400 : #737373,
        a700 : #666,
        contrast: (
                50 : #000,
                100 : #000,
                200 : #000,
                300 : #fff,
                400 : #fff,
                500 : #fff,
                600 : #fff,
                700 : #fff,
                800 : #fff,
                900 : #fff,
                a100 : #000,
                a200 : #000,
                a400 : #fff,
                a700 : #fff,
        )
);
$md-warn: (
        50 : #ffeaec,
        100 : #ffcbcf,
        200 : #ffa8af,
        300 : #ff858f,
        400 : #ff6a77,
        500 : #ff505f,
        600 : #ff4957,
        700 : #ff404d,
        800 : #ff3743,
        900 : #ff2732,
        a100 : #fff,
        a200 : #fff,
        a400 : #ffd4d6,
        a700 : #ffbbbe,
        contrast: (
                50 : #000,
                100 : #000,
                200 : #000,
                300 : #000,
                400 : #000,
                500 : #000,
                600 : #000,
                700 : #fff,
                800 : #fff,
                900 : #fff,
                a100 : #000,
                a200 : #000,
                a400 : #000,
                a700 : #000,
        )
);
$akiru-primary: mat.define-palette($md-primary);
$akiru-accent: mat.define-palette($md-accent, a200, a100, a400);
$akiru-warn: mat.define-palette($md-warn);
$typography: mat.define-typography-config($font-family: 'Montserrat',
$headline-1: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
$headline-2: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
$headline-3: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
$headline-4: mat.define-typography-level(34px, 40px, 400, 'Montserrat', normal),
$headline-5: mat.define-typography-level(45px, 48px, 400, 'Montserrat', normal), // H1
$headline-6: mat.define-typography-level(32px, 38px, 600, 'Montserrat', normal), // h2
$subtitle-1: mat.define-typography-level(24px, 31.2px, 600, 'Montserrat', normal), // h3
$subtitle-2: mat.define-typography-level(18px, 21px, 400, 'Montserrat', normal), // h4
$body-1: mat.define-typography-level(18px, 24px, 400, 'Montserrat', normal), // Bold P
$body-2: mat.define-typography-level(16px, 22px, 400, 'Montserrat', normal), // P
$caption: mat.define-typography-level(14px, 18px, 400, 'Montserrat', normal), // Caption
$button: mat.define-typography-level(14px, 22px, 400, 'Montserrat', normal)); // bolder body

$akiru-theme: mat.define-light-theme((
        color: (
                primary: $akiru-primary,
                accent: $akiru-accent,
                warn: $akiru-warn,
        ),
        typography: $typography
));

@include mat.all-component-themes($akiru-theme);
@include mat.all-component-typographies($typography);

.akiru-dark-theme {
  $dark-theme: mat.define-dark-theme((
          color: (
                  primary: $akiru-primary,
                  accent: $akiru-accent,
                  warn: $akiru-warn
          ),
  ));

  @include mat.all-component-colors($dark-theme);
}

@include mat.core;

.material-symbols-outlined {
  direction: ltr;
  display: inline-block;
  font-family: 'Material Symbols Outlined', serif;
  font-feature-settings: 'liga';
  font-size: 40px;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
}

// General akiru overrides
.akiru-filler {
  display: flex;
  flex: 1 0;
}

html {
  height: 100%;
  margin: 0;
  width: 100%;
}

body {
  background-color: #F8F6F4;
  margin: 0;
  height: 79%;
}

.holder {
  display: flex;
  justify-content: center
}

/* use this to grow */
.filler {
  display: flex;
  flex-grow: 1;
}

.aku-holder {
  display: flex;
  flex-direction: column;
  height: 100vh;
}